import * as React from 'react';
import {useUIDispatch} from "../../app/uiContext";

export const RegisterLink = ({ children, redirect, ...props }) => {
    const uiDispatch = useUIDispatch();
    const handleClick = (event) => {
        event.preventDefault();
        uiDispatch({type:`SHOW_REGISTER`, payload: redirect});
    }

    return <button className="contents" onClick={handleClick} onKeyPress={handleClick} {...props}>{children}</button>
};
